<script setup>
  import HeadCircumferenceExample from "@/components/svg/HeadCircumferenceExample.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure head size") }}
    </h1>
    <section class="columns is-mobile">
      <div class="column">
        <h2>{{ $gettext("You Need") }}</h2>
        <ul>
          <li>{{ $gettext("A head size tape") }}</li>
          <li>{{ $gettext("An assistant (if measuring an infant or small child)") }}</li>
        </ul>
      </div>
      <div class="column">
        <HeadCircumferenceExample />
      </div>
    </section>
    <div class="columns mb-6">
      <section class="column is-two-thirds">
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>
          <ol>
            <li>{{ $gettext("Ask the child to sit on a chair. Infants and young children can sit in the assistant’s lap.") }}</li>
            <li>{{ $gettext("Remove hair ornaments and hat, if any.") }}</li>
            <li>{{ $gettext("With your fingers, feel for a bump in the back of the head and position the tape over the bump.") }}</li>
            <li>{{ $gettext("Ask assistant to hold the tape in place over the bump.") }}</li>
            <li>{{ $gettext("Wrap the tape tightly around the head making sure to position it over the most prominent part of the forehead (often 1-2 fingers above the eyebrow).") }}</li>
            <li>{{ $gettext("Secure the tape using both hands and gently tug on it by moving your left hand to the left and right hand to the right.") }}</li>
            <li>{{ $gettext("Read head size measurement and record to the nearest 0.1 cm (e.g. 43.2 cm).") }}</li>
          </ol>
        </div>
      </section>
      <aside class="column card">
        <div class="card-content">
          <h2>{{ $gettext("Tips") }}</h2>
          <ul>
            <li>{{ $gettext("Use a measuring tape that cannot be stretched (non-elastic).") }}</li>
            <li>{{ $gettext("Always measure the widest possible circumference of a child’s head.") }}</li>
            <li>{{ $gettext("Make sure the tape does not cover the child’s ears.") }}</li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="columns">
      <div class="column">
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
