<template>
  <svg
    viewBox="0 0 376 307"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    class="head-circumference-example"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2"
  >
    <g v-if="race === 'c'">
      <path
        d="M278.509 412.105c-1.474 4.364-1.211 9.131 4.245 13.859-6.824 14.466-123.137-4.254-152.946-28.225 5.455-9.456 8.088-12.844 9.001-16.334 3.973-15.195-13.507-35.967-23.217-49.761-18.87-26.81-23.762-36.106-26.665-69.061-4.765-54.081 17.449-96.668 71.843-117.704 54.395-21.034 124.704-10.433 164.654 32.567 19.872 21.389 21.844 47.808 16.085 71.22-4.153 16.878-2.574 27.088.565 41.715 3.306 15.404 13.829 13.879 16.558 16.968 8.824 9.985-3.741 17.894-7.234 19.885.047 4.475 4.206 3.476 6.114 7.546 1.414 3.017 1.407 2.803.452 6.599-.955 3.796-2.214 4.134-5.411 6.275 4.484 2.15 5.219 6.518 2.998 10.092-1.754 2.631-8.394 3.791-9.952 6.546-7.138 12.625.847 19.445-13.003 26.635-4.854 2.521-29.355 7.58-39.408 7.657-14.736.114-8.6-4.485-14.679 13.521Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M283.397 138.816c-28.512-10.911-41.395-15.086-75.435-14.279-69.074 1.635-124.878 39.881-126.112 112.7-.865 51.092 17.717 71.704 34.77 101.836 17.053 30.132 16.7 26.563 22.189 42.332 16.1-13.03 20.746-31.595 49.066-46.701-26.55-8.366-27.734-43.283-16.927-56.406 12.295-14.929 42.688-12.16 47.31 6.929 3.871-3.68 3.98-5.664 7.366-9.435-2.479-25.98-3.062-31.599.845-57.171 3.907-25.573 15.239-39.091 39.083-47.78 22.241-5.128 46.633 1.165 64.754 11.75-4.934-7.222-6.079-10.303-12.421-17.945-6.343-7.642-24.94-20.801-34.488-25.83ZM333.391 319.169c9.48 13.019 24.202-.871 28.325-4.224-.316.893-.398 3.982-2.825 6.299-1.983 1.891-1.689 3.758-7.493 5.99-19.777 3.124-15.847-7.159-18.007-8.065Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M315.625 282.409s4.976 8.074 1.339 12.438c-6.057.384-13.474-.792-20.688-4.566 6.878-6.031 13.343-8.776 19.349-7.872Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M297.047 289.763c5.445-4.674 9.399-6.087 19.047-6.614 1.956-.107 3.465-1.953 5.23-3.044l-4.092-1.313c-10.786 1.901-15.652 4.476-23.186 10.971 7.152 3.798 15.441 5.105 23.417 7.228l2.852-3.335c-4.951.173-14.008 1.419-23.268-3.893Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M318.889 289.428c0 3.739-2.14 6.769-4.781 6.769-2.64 0-4.781-3.03-4.781-6.769 0-3.739 2.141-6.769 4.781-6.769 2.641 0 4.781 3.03 4.781 6.769ZM291.592 283.268s10.949-9.547 17.203-12.547c6.255-3.001 13.891-3.273 16.483-4.092 2.592-.818 2.592-7.855-2.045-7.337-4.637.518-11.729 3.519-16.367 6.247-4.637 2.727-15.274 17.729-15.274 17.729ZM187.082 280.815s18.106-2.901 22.525 19.626c0 0-18.902 4.966-17.032 13.851 1.871 8.885 7.679 12.107 7.679 12.107s-18.706-4.761-15.432-14.289c3.273-9.529 17.067-11.286 15.664-16.898-1.403-5.611-13.404-14.397-13.404-14.397ZM358.632 338.114s-13.509 9.54-18.999 7.903c0 0 .775-10.476-6.242-18.194 2.862.496 5.205 29.469-3.085 38.317 3.511-2.528 5.283-9.371 9.327-17.668 0 0 9.188 1.896 12.92-.818 3.731-2.715 5.656-3.259 6.079-9.54Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m342.871 355.018 10.149 5.003s-5.785.786-7.421 4.271l-2.728-9.274ZM285.175 393.08c29.906 2.564 41.789-19.315 41.789-19.315s-.534 15.61 9.057 15.289c0 0-31.194 15.694-49.267 12.685l-7.381 7.683c-.169-2.761-8.405-8.157-11.674-10.944-6.49-5.535-9.973-13.818-9.219-22.314l.038-.412s9.465 15.853 26.657 17.328Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m114.758 290.942 41.234-7.026s6.692-1.409 5.41-8.934l-4.743-27.833s-1.522-9.007-8.271-7.857l-41.234 7.026c-9.018 2.281-10.448 13.92-10.448 13.92-.249.64-3.542.846-13.717 1.254 0 4.823 2.549 19.313 4.045 19.374 7.707.31 13.019-.99 13.019-.99s6.615 12.444 14.705 11.066Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m114.758 290.942 41.234-7.026s6.692-1.409 5.41-8.934l-4.743-27.833s-1.522-9.007-8.271-7.857l-41.234 7.026c-9.018 2.281-10.448 13.92-10.448 13.92-.249.64-3.542.846-13.717 1.254 0 4.823 2.549 19.313 4.045 19.374 7.707.31 13.019-.99 13.019-.99s6.615 12.444 14.705 11.066Z"
        class="gray-stroke"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m134.591 282.212-4.129-4.461-2.44 5.581 6.569-1.12ZM128.979 249.284l-2.418 5.576-4.149-4.457 6.567-1.119Z"
        class="orange-arrow"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m341.691 216.469 4.593 20.235c-9.897 2.216-19.642 4.22-29.462 6.202a2543.901 2543.901 0 0 1-29.427 5.712l-3.838-20.391a2529.473 2529.473 0 0 0 29.179-5.663c9.686-1.953 19.433-3.961 28.955-6.095Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m341.691 216.469 4.593 20.235c-9.897 2.216-19.642 4.22-29.462 6.202a2543.901 2543.901 0 0 1-29.427 5.712l-3.838-20.391a2529.473 2529.473 0 0 0 29.179-5.663c9.686-1.953 19.433-3.961 28.955-6.095Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M287.395 248.618a2543.901 2543.901 0 0 0 29.427-5.712c9.82-1.982 17.565-2.986 27.462-5.202 0 0 2.807-13.02-1.593-21.235-9.522 2.134-20.269 4.142-29.955 6.095a2529.473 2529.473 0 0 1-29.179 5.663"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M287.395 248.618a2543.901 2543.901 0 0 0 29.427-5.712c9.82-1.982 17.565-2.986 27.462-5.202 0 0 2.807-13.02-1.593-21.235-9.522 2.134-20.269 4.142-29.955 6.095a2529.473 2529.473 0 0 1-29.179 5.663"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m339.034 238.298-1-4.638M324.212 241.407l-.953-4.65M309.715 244.32l-.919-4.656M287.395 248.618l-2.22-11.795M316.964 242.877l-1.883-9.372M331.621 239.872l-1.962-9.357M302.492 245.732l-1.823-9.384M295.267 247.124l-.892-4.662"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m282.754 228.377 3.831 20.392a3345.022 3345.022 0 0 1-58.958 10.503l-3.462-20.458c19.567-3.31 39.101-6.78 58.589-10.437Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m282.754 228.377 3.831 20.392a3345.022 3345.022 0 0 1-58.958 10.503l-3.462-20.458c19.567-3.31 39.101-6.78 58.589-10.437Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M286.585 248.769a3345.022 3345.022 0 0 1-58.958 10.503"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M286.585 248.769a3345.022 3345.022 0 0 1-58.958 10.503"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M224.165 238.814c19.567-3.31 39.101-6.78 58.589-10.437"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M224.165 238.814c19.567-3.31 39.101-6.78 58.589-10.437"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m279.373 250.118-.864-4.666M264.529 252.827l-.842-4.671M249.999 255.41l-.821-4.675M227.627 259.272l-2.003-11.834M257.265 254.127l-1.674-9.411M271.952 251.479l-1.718-9.404M242.76 256.678l-1.635-9.419M235.516 257.921l-.801-4.677"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m223.355 238.949 3.463 20.457-58.837 9.737-3.586-20.436 58.96-9.758Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m223.355 238.949 3.463 20.457-58.837 9.737-3.586-20.436 58.96-9.758Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m226.818 259.406-58.837 9.737"
        class="no-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m226.818 259.406-58.837 9.737"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m164.395 248.707 58.96-9.758"
        class="no-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m164.395 248.707 58.96-9.758"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m219.613 260.598-.795-4.679M204.791 263.051l-.802-4.677M190.292 265.451l-.81-4.676M167.981 269.143l-2.074-11.821M197.542 264.251l-1.624-9.42M212.202 261.825l-1.609-9.423M183.07 266.646l-1.638-9.418M175.848 267.841l-.817-4.674"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m163.585 248.841 3.587 20.436-29.418 4.869-14.709 2.434-3.678.608c-.593.103-1.268.201-1.795.307l-1.321.316a17.55 17.55 0 0 0-1.408.37l-1.474.423c-.973.347-1.959.623-2.923 1.027l-7.391-19.387c1.336-.546 2.742-.969 4.117-1.442l2.112-.61a32.462 32.462 0 0 1 2.2-.574l2.315-.526c.692-.135 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m163.585 248.841 3.587 20.436-29.418 4.869-14.709 2.434-3.678.608c-.593.103-1.268.201-1.795.307l-1.321.316a17.55 17.55 0 0 0-1.408.37l-1.474.423c-.973.347-1.959.623-2.923 1.027l-7.391-19.387c1.336-.546 2.742-.969 4.117-1.442l2.112-.61a32.462 32.462 0 0 1 2.2-.574l2.315-.526c.692-.135 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m167.172 269.277-29.418 4.869-14.709 2.434-3.678.608c-.593.103-1.268.201-1.795.307l-1.321.316c-.422.084-.912.225-1.408.371l-1.474.422c-.973.347-1.959.623-2.923 1.027"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M103.055 260.244c1.336-.546 2.742-.969 4.117-1.442l2.112-.61a32.462 32.462 0 0 1 2.2-.574l2.315-.526c.692-.135 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M103.055 260.244c1.336-.546 2.742-.969 4.117-1.442l2.112-.61a32.462 32.462 0 0 1 2.2-.574l2.315-.526c.692-.135 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m159.968 270.469-.825-4.674M145.146 272.922l-.831-4.672M130.646 275.322l-.838-4.671M110.446 279.631l-4.275-11.214M137.896 274.122l-1.681-9.411M152.557 271.696l-1.667-9.413M123.424 276.517l-1.695-9.408M116.62 277.725l-1.028-4.633"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m102.165 260.589 7.64 19.29c-7.763 3.065-15.139 8.096-21.787 14.133-1.692 1.535-3.192 3.02-5.025 4.85l-5.286 5.267-10.573 10.535-14.644-14.699 10.572-10.534 5.286-5.266c1.687-1.686 3.722-3.705 5.717-5.509 8.068-7.306 17.326-13.786 28.1-18.067Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m102.165 260.589 7.64 19.29c-7.763 3.065-15.139 8.096-21.787 14.133-1.692 1.535-3.192 3.02-5.025 4.85l-5.286 5.267-10.573 10.535-14.644-14.699 10.572-10.534 5.286-5.266c1.687-1.686 3.722-3.705 5.717-5.509 8.068-7.306 17.326-13.786 28.1-18.067Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M109.805 279.879c-7.763 3.065-15.139 8.096-21.787 14.133-1.692 1.535-3.192 3.02-5.025 4.85l-5.286 5.267-37.121 37.083"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m25.941 326.514 37.121-37.083 5.286-5.266c1.687-1.686 3.722-3.705 5.717-5.509 8.068-7.306 17.326-13.786 28.1-18.067"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m25.941 326.514 37.121-37.083 5.286-5.266c1.687-1.686 3.722-3.705 5.717-5.509 8.068-7.306 17.326-13.786 28.1-18.067"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m104.178 282.472-2.209-4.2M93.164 289.669l-2.924-3.738M83.171 298.685l-3.35-3.362M40.586 341.212s-7.323 7.349-14.645 0c-7.323-7.349 0-14.698 0-14.698M88.114 293.923l-6.419-7.084M98.56 285.784l-5.24-7.995M77.98 303.858l-6.748-6.773M72.788 309.03l-3.349-3.363"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m105.862 255.805 6.564-1.119 4.749 27.871-6.564 1.119-4.749-27.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m112.426 254.686 4.749 27.871"
        class="no-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M112.426 254.686c.599 2.288 1.089 4.595 1.564 6.903.486 2.308.895 4.628 1.302 6.949.385 2.325.768 4.649 1.074 6.987l.457 3.508c.115 1.175.265 2.344.352 3.524-.31-1.141-.556-2.295-.836-3.442l-.731-3.46c-.485-2.308-.894-4.628-1.301-6.949-.383-2.325-.766-4.65-1.072-6.988-.317-2.336-.617-4.675-.809-7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m110.611 283.676-4.749-27.871"
        class="no-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M110.611 283.676c-.599-2.288-1.09-4.594-1.565-6.904-.486-2.307-.894-4.627-1.302-6.948-.385-2.324-.767-4.649-1.074-6.987l-.456-3.507c-.116-1.175-.266-2.345-.352-3.525.31 1.142.555 2.295.836 3.443l.73 3.46c.485 2.307.895 4.627 1.301 6.949.384 2.324.766 4.649 1.072 6.987.317 2.336.618 4.675.81 7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m141.57 249.72 15.089-2.571 4.749 27.871-15.089 2.571-4.749-27.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m156.659 247.149 4.749 27.871"
        class="no-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m156.659 247.149 4.749 27.871"
        class="gray-stroke"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="m146.319 277.591-4.749-27.871"
        class="no-fill"
        transform="translate(-21.568 -124.44)"
      />
      <path
        d="M146.319 277.591c-.599-2.288-1.091-4.593-1.565-6.903-.486-2.307-.894-4.628-1.303-6.948-.384-2.324-.767-4.65-1.073-6.988l-.456-3.506c-.117-1.176-.266-2.345-.352-3.526.309 1.143.555 2.296.835 3.444l.731 3.46c.485 2.307.894 4.627 1.301 6.948.383 2.325.766 4.649 1.072 6.987.316 2.337.617 4.675.81 7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.44)"
      />
      <g v-if="showArrows">
        <path
          d="m67.64 248.297-26.043-26.044-12.464 12.465 26.043 26.044-5.798 5.798 22.449-1.612 1.612-22.45-5.799 5.799ZM199.106 221.245v-36.832h-17.628v36.832h-8.2l17.014 14.734 17.014-14.734h-8.2Z"
          class="orange-arrow"
          transform="translate(-21.568 -124.44)"
        />
        <path
          d="M199.106 221.245v-36.832h-17.628v36.832h-8.2l17.014 14.734 17.014-14.734h-8.2Z"
          style="fill:none;stroke:#fff;stroke-width:1px"
          transform="translate(-21.568 -124.44)"
        />
        <path
          d="m371.252 212.13 26.043-26.043-12.464-12.465-26.044 26.043-5.798-5.797 1.612 22.449 22.449 1.611-5.798-5.798Z"
          class="orange-arrow"
          transform="translate(-21.568 -124.44)"
        />
      </g>
    </g>
    <g v-else-if="race === 'b'">
      <path
        d="M278.509 412.105c-1.474 4.364-1.211 9.131 4.245 13.859-6.824 14.466-123.137-4.253-152.946-28.225 5.455-9.456 11.098-7.67 9.001-16.334-3.695-15.265-13.507-35.967-23.217-49.762-18.87-26.809-23.762-36.105-26.665-69.059-4.765-54.081 17.449-96.67 71.843-117.704 54.395-21.035 124.704-10.434 164.654 32.565 19.872 21.39 19.196 36.152 18.86 60.258-.278 19.957-2.264 24.78-2.264 41.706 0 18.062 9.889 20.833 12.448 27.939 4.515 12.537-5.578 17.894-9.07 19.885.047 4.477 2.206 3.478 4.113 7.546 1.415 3.018 1.408 2.805.453 6.601-.955 3.795-2.214 4.132-5.411 6.273 4.483 2.151 5.219 6.519 2.998 10.093-1.754 2.631-6.394 3.791-7.952 6.547-7.138 12.624.847 18.444-13.003 25.634-4.854 2.52-23.355 8.58-33.408 8.658-14.736.112-8.6-4.486-14.679 13.52Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M283.397 138.816c-28.512-10.912-41.395-15.086-75.435-14.28-69.074 1.636-124.878 39.882-126.112 112.701-.865 51.091 17.717 71.704 34.77 101.836 17.053 30.131 16.7 26.562 22.189 42.332 16.1-13.029 20.746-31.596 49.066-46.701-26.55-8.365-27.734-43.283-16.927-56.406 12.295-14.93 42.688-12.16 47.31 6.929 3.871-3.68 3.98-5.664 7.366-9.435-2.479-25.981-3.062-31.599.845-57.172 3.907-25.572 15.239-39.091 39.083-47.779 22.241-5.127 46.633 1.165 64.754 11.75-4.934-7.222-6.079-10.303-12.421-17.945-6.343-7.642-24.94-20.801-34.488-25.83ZM336.871 320.9c14.287 2.314 14.594-4.93 18.717-8.283-.315.894.401 3.946-1.601 7.463-1.403 2.465-2.991 3.29-8.589 7.153-4.242 2.182-6.367-5.428-8.527-6.333Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M316.448 283.662s3.707 8.952-.364 13.837c-8.72-.014-10.314-5.18-18.387-9.405 8.483-4.696 11.786-4.074 18.751-4.432Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M297.697 287.901c11.906-3.766 16.271-2.575 20.781-2.345 2.189.113 1.112-1.421 2.046-2.857-12.045-1.653-17.908-.11-25.703 5.209 8.546 5.892 14.42 10.639 22.471 10.363l1.556-1.213c-8.939.441-11.102-4.2-21.151-9.157Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M318.848 291.21c0 3.906-1.829 7.073-4.588 7.073-2.76 0-4.589-3.167-4.589-7.073 0-3.908 2.23-7.075 4.989-7.075s4.188 3.167 4.188 7.075ZM283.54 268.766s15.549-6.915 23.585-8.119c8.036-1.204 16.68 1.122 19.864 1.1 3.183-.024 5.608-7.902.238-8.92-5.37-1.018-14.344-.103-20.475 1.353-6.132 1.456-23.212 14.586-23.212 14.586ZM187.082 280.815s18.106-2.902 22.525 19.625c0 0-18.902 4.967-17.032 13.852 1.871 8.884 7.679 12.107 7.679 12.107s-18.706-4.761-15.432-14.29c3.273-9.528 17.067-11.285 15.664-16.896-1.403-5.613-13.404-14.398-13.404-14.398ZM350.632 338.114s-9.509 9.539-14.999 7.902c0 0-.49-12.844-4.327-18.474 0 0 5.085 19.858-3.047 33.222 6.701-2.811 3.33-3.995 7.374-12.293 0 0 5.188 1.897 8.92-.818 3.731-2.714 5.656-3.258 6.079-9.539ZM336.871 355.018l8.149 5.002s-3.785.787-5.421 4.273l-2.728-9.275ZM285.175 393.079c29.906 2.565 35.789-20.314 35.789-20.314s-.534 15.61 9.057 15.289c0 0-29.194 12.693-47.267 9.685l-3.381 11.683c-.169-2.761-8.405-8.157-11.674-10.944-6.49-5.535-9.973-13.818-9.219-22.315l.038-.411s9.465 15.852 26.657 17.327Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m114.758 290.942 41.234-7.026s6.692-1.409 5.41-8.935l-4.743-27.832s-1.522-9.007-8.271-7.857l-41.234 7.026c-9.018 2.281-10.448 13.92-10.448 13.92-.249.64-3.542.847-13.717 1.254 0 4.822 2.549 19.312 4.045 19.373 7.707.311 13.019-.988 13.019-.988s6.615 12.443 14.705 11.065Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m114.758 290.942 41.234-7.026s6.692-1.409 5.41-8.935l-4.743-27.832s-1.522-9.007-8.271-7.857l-41.234 7.026c-9.018 2.281-10.448 13.92-10.448 13.92-.249.64-3.542.847-13.717 1.254 0 4.822 2.549 19.312 4.045 19.373 7.707.311 13.019-.988 13.019-.988s6.615 12.443 14.705 11.065Z"
        class="gray-stroke"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m134.591 282.212-4.129-4.461-2.44 5.58 6.569-1.119ZM128.979 249.283l-2.418 5.577-4.149-4.458 6.567-1.119Z"
        class="orange-arrow"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m341.691 216.469 4.593 20.234c-9.897 2.218-19.642 4.221-29.462 6.203a2543.901 2543.901 0 0 1-29.427 5.712l-3.838-20.391a2529.473 2529.473 0 0 0 29.179-5.663c9.686-1.954 19.433-3.961 28.955-6.095Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m341.691 216.469 4.593 20.234c-9.897 2.218-19.642 4.221-29.462 6.203a2543.901 2543.901 0 0 1-29.427 5.712l-3.838-20.391a2529.473 2529.473 0 0 0 29.179-5.663c9.686-1.954 19.433-3.961 28.955-6.095Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M287.395 248.618a2543.901 2543.901 0 0 0 29.427-5.712c9.82-1.982 17.565-2.985 27.462-5.203 0 0 2.807-13.019-1.593-21.234-9.522 2.134-20.269 4.141-29.955 6.095a2529.473 2529.473 0 0 1-29.179 5.663"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M287.395 248.618a2543.901 2543.901 0 0 0 29.427-5.712c9.82-1.982 17.565-2.985 27.462-5.203 0 0 2.807-13.019-1.593-21.234-9.522 2.134-20.269 4.141-29.955 6.095a2529.473 2529.473 0 0 1-29.179 5.663"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m339.034 238.298-1-4.639M324.212 241.407l-.953-4.65M309.715 244.32l-.919-4.656M287.395 248.618l-2.22-11.795M316.964 242.878l-1.883-9.373M331.621 239.871l-1.962-9.356M302.492 245.732l-1.823-9.384M295.267 247.124l-.892-4.662"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m282.754 228.378 3.831 20.391a3354.261 3354.261 0 0 1-58.958 10.503l-3.462-20.458a3339.534 3339.534 0 0 0 58.589-10.436Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m282.754 228.378 3.831 20.391a3354.261 3354.261 0 0 1-58.958 10.503l-3.462-20.458a3339.534 3339.534 0 0 0 58.589-10.436Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M286.585 248.769a3354.261 3354.261 0 0 1-58.958 10.503"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M286.585 248.769a3354.261 3354.261 0 0 1-58.958 10.503"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M224.165 238.814a3339.534 3339.534 0 0 0 58.589-10.436"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M224.165 238.814a3339.534 3339.534 0 0 0 58.589-10.436"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m279.373 250.118-.864-4.666M264.529 252.826l-.842-4.67M249.999 255.41l-.821-4.675M227.627 259.272l-2.003-11.834M257.265 254.128l-1.674-9.412M271.952 251.478l-1.718-9.403M242.76 256.677l-1.635-9.418M235.516 257.922l-.801-4.678"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m223.355 238.948 3.463 20.458-58.837 9.737-3.586-20.436 58.96-9.759Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m223.355 238.948 3.463 20.458-58.837 9.737-3.586-20.436 58.96-9.759Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m226.818 259.406-58.837 9.737"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m226.818 259.406-58.837 9.737"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m164.395 248.707 58.96-9.759"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m164.395 248.707 58.96-9.759"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m219.613 260.598-.795-4.678M204.791 263.051l-.802-4.677M190.292 265.451l-.81-4.677M167.981 269.143l-2.074-11.821M197.542 264.251l-1.624-9.42M212.202 261.825l-1.609-9.423M183.07 266.646l-1.638-9.418M175.848 267.841l-.817-4.675"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m163.585 248.84 3.587 20.437-29.418 4.868-14.709 2.435-3.678.608c-.593.103-1.268.201-1.795.307l-1.321.315c-.422.085-.912.225-1.408.371l-1.474.422c-.973.347-1.959.624-2.923 1.028l-7.391-19.387c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.08 33.08 0 0 1 2.2-.573l2.315-.527c.692-.135 1.25-.211 1.881-.321l3.685-.61 14.74-2.44 29.48-4.88Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m163.585 248.84 3.587 20.437-29.418 4.868-14.709 2.435-3.678.608c-.593.103-1.268.201-1.795.307l-1.321.315c-.422.085-.912.225-1.408.371l-1.474.422c-.973.347-1.959.624-2.923 1.028l-7.391-19.387c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.08 33.08 0 0 1 2.2-.573l2.315-.527c.692-.135 1.25-.211 1.881-.321l3.685-.61 14.74-2.44 29.48-4.88Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m167.172 269.277-29.418 4.868-14.709 2.435-3.678.608c-.593.103-1.268.201-1.795.307l-1.321.315c-.422.085-.912.225-1.408.371l-1.474.422c-.973.347-1.959.624-2.923 1.028"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M103.055 260.244c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.08 33.08 0 0 1 2.2-.573l2.315-.527c.692-.135 1.25-.211 1.881-.321l3.685-.61 14.74-2.44 29.48-4.88"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M103.055 260.244c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.08 33.08 0 0 1 2.2-.573l2.315-.527c.692-.135 1.25-.211 1.881-.321l3.685-.61 14.74-2.44 29.48-4.88"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m159.968 270.469-.825-4.673M145.146 272.922l-.831-4.672M130.646 275.322l-.838-4.672M110.446 279.631l-4.275-11.214M137.896 274.122l-1.681-9.41M152.557 271.696l-1.667-9.413M123.424 276.517l-1.695-9.408M116.62 277.725l-1.028-4.634"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m102.165 260.589 7.64 19.291c-7.763 3.064-15.139 8.095-21.787 14.131-1.692 1.536-3.192 3.021-5.025 4.851l-5.286 5.268-10.573 10.534-14.644-14.699 15.858-15.801c1.687-1.685 3.722-3.703 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m102.165 260.589 7.64 19.291c-7.763 3.064-15.139 8.095-21.787 14.131-1.692 1.536-3.192 3.021-5.025 4.851l-5.286 5.268-10.573 10.534-14.644-14.699 15.858-15.801c1.687-1.685 3.722-3.703 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M109.805 279.88c-7.763 3.064-15.139 8.095-21.787 14.131-1.692 1.536-3.192 3.021-5.025 4.851l-5.286 5.268-37.121 37.082"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m25.941 326.514 37.121-37.083 5.286-5.267c1.687-1.685 3.722-3.703 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m25.941 326.514 37.121-37.083 5.286-5.267c1.687-1.685 3.722-3.703 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m104.178 282.472-2.209-4.2M93.164 289.67l-2.924-3.739M83.171 298.685l-3.35-3.362M40.586 341.212s-7.323 7.349-14.645 0c-7.323-7.349 0-14.698 0-14.698M88.114 293.923l-6.419-7.084M98.56 285.783l-5.24-7.994M77.98 303.857l-6.748-6.771M72.788 309.03l-3.349-3.362"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m105.862 255.804 6.564-1.118 4.749 27.871-6.564 1.118-4.749-27.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m112.426 254.686 4.749 27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M112.426 254.686c.599 2.288 1.089 4.595 1.564 6.903.486 2.308.895 4.628 1.302 6.949.385 2.324.768 4.649 1.074 6.987l.457 3.507c.115 1.176.265 2.345.352 3.525-.31-1.142-.556-2.295-.836-3.442l-.731-3.461c-.485-2.308-.894-4.628-1.301-6.948-.383-2.326-.766-4.65-1.072-6.988-.317-2.336-.617-4.674-.809-7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m110.611 283.675-4.749-27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M110.611 283.675c-.599-2.288-1.09-4.593-1.565-6.903-.486-2.308-.894-4.628-1.302-6.948-.385-2.324-.767-4.65-1.074-6.987l-.456-3.507c-.116-1.176-.266-2.345-.352-3.526.31 1.143.555 2.296.836 3.444l.73 3.46c.485 2.307.895 4.628 1.301 6.948.384 2.325.766 4.649 1.072 6.987.317 2.336.618 4.675.81 7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m141.57 249.72 15.089-2.571 4.749 27.871-15.089 2.571-4.749-27.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m156.659 247.149 4.749 27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m156.659 247.149 4.749 27.871"
        class="gray-stroke"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m146.319 277.591-4.749-27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M146.319 277.591c-.599-2.288-1.091-4.594-1.565-6.903-.486-2.308-.894-4.628-1.303-6.948-.384-2.324-.767-4.65-1.073-6.987l-.456-3.507c-.117-1.176-.266-2.345-.352-3.526.309 1.143.555 2.295.835 3.443l.731 3.461c.485 2.307.894 4.628 1.301 6.948.383 2.324.766 4.649 1.072 6.987.316 2.336.617 4.675.81 7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.439)"
      />
      <g v-if="showArrows">
        <path
          d="m67.64 248.297-26.043-26.043-12.464 12.464 26.043 26.043-5.798 5.799 22.449-1.612 1.612-22.449-5.799 5.798ZM199.106 221.244v-36.831h-17.628v36.831h-8.2l17.014 14.735 17.014-14.735h-8.2Z"
          class="orange-arrow"
          transform="translate(-21.568 -124.439)"
        />
        <path
          d="M199.106 221.244v-36.831h-17.628v36.831h-8.2l17.014 14.735 17.014-14.735h-8.2Z"
          style="fill:none;stroke:#fff;stroke-width:1px"
          transform="translate(-21.568 -124.439)"
        />
        <path
          d="m371.252 212.13 26.043-26.043-12.464-12.465-26.044 26.043-5.798-5.798 1.612 22.45 22.449 1.611-5.798-5.798Z"
          class="orange-arrow"
          transform="translate(-21.568 -124.439)"
        />
      </g>
    </g>
    <g v-else>
      <path
        d="M278.509 412.105c-1.474 4.364-1.211 9.131 4.245 13.859-6.824 14.467-123.137-4.254-152.946-28.225 5.455-9.456 11.098-7.67 9.001-16.334-3.695-15.265-13.507-35.967-23.217-49.762-18.87-26.809-23.762-36.105-26.665-69.06-4.765-54.081 17.449-96.669 71.843-117.704 54.395-21.034 124.704-10.433 164.654 32.566 19.872 21.39 21.844 47.81 16.085 71.22-4.153 16.879-5.235 27.789 1.775 41.005 5.395 10.169 11.518 6.525 14.077 13.632 4.515 12.537-8.471 21.94-11.963 23.931.047 4.476 2.206 3.477 4.113 7.546 1.415 3.018 1.408 2.805.453 6.6-.955 3.796-2.214 4.134-5.411 6.274 4.483 2.151 5.219 6.52 2.998 10.093-1.754 2.631-6.394 3.791-7.952 6.546-7.138 12.624-1.153 18.445-15.003 25.635-4.854 2.52-21.355 8.58-31.408 8.657-14.736.113-8.6-4.485-14.679 13.521Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M283.397 138.815c-28.512-10.911-41.395-15.085-75.435-14.279-69.074 1.636-124.878 39.882-126.112 112.7-.865 51.092 17.717 71.705 34.77 101.837 17.053 30.131 16.7 26.563 22.189 42.332 16.1-13.029 20.746-31.595 49.066-46.701-26.55-8.365-27.734-43.283-16.927-56.406 12.295-14.93 42.688-12.16 47.31 6.929 3.871-3.68 3.98-5.664 7.366-9.435-2.479-25.981-3.062-31.599.845-57.171 3.907-25.573 15.239-39.092 39.083-47.78 22.241-5.128 46.633 1.165 64.754 11.75-4.934-7.222-6.079-10.303-12.421-17.945-6.343-7.642-24.94-20.801-34.488-25.831ZM331.306 318.432c19.326 6.418 22.039-4.311 26.163-7.664-.316.894 0 4.123-2.428 6.439-1.982 1.892-3.839 7.795-9.643 10.026-4.242 2.181-11.932-7.896-14.092-8.801Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M315.625 282.409s2.743 9.805-.894 14.17c-6.057.383-11.241-2.524-18.455-6.299l19.349-7.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M297.047 289.763c5.879-.536 12.283-2.937 19.047-6.614 1.72-.935 3.465-1.954 5.23-3.045l-4.092-1.312c-7.009 5.322-14.625 8.472-23.186 10.971 7.152 3.797 14.54 7.114 22.515 9.237l2.853-3.335c-4.951.172-13.107-.591-22.367-5.902Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M318.889 289.428c0 3.738-2.14 6.769-4.781 6.769-2.64 0-4.781-3.031-4.781-6.769 0-3.739 2.141-6.77 4.781-6.77 2.641 0 4.781 3.031 4.781 6.77ZM291.592 283.268s10.949-9.547 17.203-12.547c6.255-3.001 13.891-3.273 16.483-4.092 2.592-.818 2.592-7.855-2.045-7.337-4.637.518-11.729 3.518-16.367 6.246-4.637 2.728-15.274 17.73-15.274 17.73ZM187.082 280.814s18.106-2.901 22.525 19.626c0 0-18.902 4.966-17.032 13.851 1.871 8.886 7.679 12.108 7.679 12.108s-18.706-4.76-15.432-14.29c3.273-9.528 17.067-11.285 15.664-16.897-1.403-5.611-13.404-14.398-13.404-14.398ZM350.632 338.114s-11.509 9.539-16.999 7.903c0 0 1.51-12.845-2.327-18.476 0 0 3.085 19.859-5.047 33.223 6.701-2.811 3.33-3.995 7.374-12.292 0 0 7.188 1.896 10.92-.819 3.731-2.714 5.656-3.258 6.079-9.539ZM336.871 355.018l8.149 5.002s-3.785.787-5.421 4.272l-2.728-9.274ZM285.175 393.079c29.906 2.565 33.789-20.314 33.789-20.314s-.534 15.61 9.057 15.289c0 0-27.194 12.693-45.267 9.685l-3.381 11.683c-.169-2.761-8.405-8.157-11.674-10.944-6.49-5.535-9.973-13.818-9.219-22.315l.038-.411s9.465 15.852 26.657 17.327Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m114.758 290.942 41.234-7.027s6.692-1.408 5.41-8.934l-4.743-27.832s-1.522-9.007-8.271-7.857l-41.234 7.026c-9.018 2.281-10.448 13.92-10.448 13.92-.249.64-3.542.847-13.717 1.254 0 4.822 2.549 19.313 4.045 19.373 7.707.311 13.019-.989 13.019-.989s6.615 12.444 14.705 11.066Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m114.758 290.942 41.234-7.027s6.692-1.408 5.41-8.934l-4.743-27.832s-1.522-9.007-8.271-7.857l-41.234 7.026c-9.018 2.281-10.448 13.92-10.448 13.92-.249.64-3.542.847-13.717 1.254 0 4.822 2.549 19.313 4.045 19.373 7.707.311 13.019-.989 13.019-.989s6.615 12.444 14.705 11.066Z"
        class="gray-stroke"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m134.591 282.212-4.129-4.461-2.44 5.58 6.569-1.119ZM128.979 249.283l-2.418 5.577-4.149-4.458 6.567-1.119Z"
        class="orange-arrow"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m341.691 216.47 4.593 20.233c-9.897 2.217-19.642 4.222-29.462 6.203a2565.289 2565.289 0 0 1-29.427 5.712l-3.838-20.391a2508.348 2508.348 0 0 0 29.179-5.664c9.686-1.953 19.433-3.96 28.955-6.093Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m341.691 216.47 4.593 20.233c-9.897 2.217-19.642 4.222-29.462 6.203a2565.289 2565.289 0 0 1-29.427 5.712l-3.838-20.391a2508.348 2508.348 0 0 0 29.179-5.664c9.686-1.953 19.433-3.96 28.955-6.093Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M287.395 248.618a2565.289 2565.289 0 0 0 29.427-5.712c9.82-1.981 17.565-2.986 27.462-5.203 0 0 2.807-13.019-1.593-21.233-9.522 2.133-20.269 4.14-29.955 6.093a2508.348 2508.348 0 0 1-29.179 5.664"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M287.395 248.618a2565.289 2565.289 0 0 0 29.427-5.712c9.82-1.981 17.565-2.986 27.462-5.203 0 0 2.807-13.019-1.593-21.233-9.522 2.133-20.269 4.14-29.955 6.093a2508.348 2508.348 0 0 1-29.179 5.664"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m339.034 238.298-1-4.639M324.212 241.407l-.953-4.65M309.715 244.32l-.919-4.656M287.395 248.618l-2.22-11.795M316.964 242.877l-1.883-9.372M331.621 239.871l-1.962-9.356M302.492 245.732l-1.823-9.384M295.267 247.123l-.892-4.661"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m282.754 228.377 3.831 20.392a3354.261 3354.261 0 0 1-58.958 10.503l-3.462-20.458a3330.277 3330.277 0 0 0 58.589-10.437Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m282.754 228.377 3.831 20.392a3354.261 3354.261 0 0 1-58.958 10.503l-3.462-20.458a3330.277 3330.277 0 0 0 58.589-10.437Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M286.585 248.769a3354.261 3354.261 0 0 1-58.958 10.503"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M286.585 248.769a3354.261 3354.261 0 0 1-58.958 10.503"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M224.165 238.814a3330.277 3330.277 0 0 0 58.589-10.437"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M224.165 238.814a3330.277 3330.277 0 0 0 58.589-10.437"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m279.373 250.118-.864-4.666M264.529 252.826l-.842-4.67M249.999 255.41l-.821-4.675M227.627 259.272l-2.003-11.834M257.265 254.128l-1.674-9.412M271.952 251.478l-1.718-9.403M242.76 256.678l-1.635-9.419M235.516 257.921l-.801-4.677"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m223.355 238.948 3.463 20.458-58.837 9.737-3.586-20.437 58.96-9.758Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m223.355 238.948 3.463 20.458-58.837 9.737-3.586-20.437 58.96-9.758Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m226.818 259.406-58.837 9.737"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m226.818 259.406-58.837 9.737"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m164.395 248.706 58.96-9.758"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m164.395 248.706 58.96-9.758"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m219.613 260.598-.795-4.679M204.791 263.051l-.802-4.678M190.292 265.451l-.81-4.677M167.981 269.143l-2.074-11.821M197.542 264.251l-1.624-9.42M212.202 261.825l-1.609-9.423M183.07 266.646l-1.638-9.418M175.848 267.841l-.817-4.675"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m163.585 248.841 3.587 20.436-29.418 4.868-14.709 2.435-3.678.608c-.593.102-1.268.201-1.795.307l-1.321.315c-.422.085-.912.225-1.408.372l-1.474.421c-.973.347-1.959.624-2.923 1.028l-7.391-19.387c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.064 33.064 0 0 1 2.2-.574l2.315-.525c.692-.136 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m163.585 248.841 3.587 20.436-29.418 4.868-14.709 2.435-3.678.608c-.593.102-1.268.201-1.795.307l-1.321.315c-.422.085-.912.225-1.408.372l-1.474.421c-.973.347-1.959.624-2.923 1.028l-7.391-19.387c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.064 33.064 0 0 1 2.2-.574l2.315-.525c.692-.136 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m167.172 269.277-29.418 4.868-14.709 2.435-3.678.608c-.593.102-1.268.201-1.795.307l-1.321.315c-.422.085-.912.225-1.408.372l-1.474.421c-.973.347-1.959.624-2.923 1.028"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M103.055 260.244c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.064 33.064 0 0 1 2.2-.574l2.315-.525c.692-.136 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M103.055 260.244c1.336-.546 2.742-.97 4.117-1.442l2.112-.611a33.064 33.064 0 0 1 2.2-.574l2.315-.525c.692-.136 1.25-.213 1.881-.322l3.685-.61 14.74-2.44 29.48-4.879"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m159.968 270.469-.825-4.674M145.146 272.922l-.831-4.673M130.646 275.322l-.838-4.672M110.446 279.631l-4.275-11.215M137.896 274.122l-1.681-9.411M152.557 271.696l-1.667-9.413M123.424 276.517l-1.695-9.408M116.62 277.725l-1.028-4.633"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m102.165 260.589 7.64 19.29c-7.763 3.065-15.139 8.096-21.787 14.133-1.692 1.535-3.192 3.02-5.025 4.85l-5.286 5.267-10.573 10.535-14.644-14.698 10.572-10.535 5.286-5.267c1.687-1.686 3.722-3.704 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m102.165 260.589 7.64 19.29c-7.763 3.065-15.139 8.096-21.787 14.133-1.692 1.535-3.192 3.02-5.025 4.85l-5.286 5.267-10.573 10.535-14.644-14.698 10.572-10.535 5.286-5.267c1.687-1.686 3.722-3.704 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M109.805 279.879c-7.763 3.065-15.139 8.096-21.787 14.133-1.692 1.535-3.192 3.02-5.025 4.85l-5.286 5.267-37.121 37.083"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m25.941 326.514 37.121-37.083 5.286-5.267c1.687-1.686 3.722-3.704 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m25.941 326.514 37.121-37.083 5.286-5.267c1.687-1.686 3.722-3.704 5.717-5.509 8.068-7.305 17.326-13.785 28.1-18.066"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linecap:square"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m104.178 282.472-2.209-4.201M93.164 289.67l-2.924-3.738M83.171 298.685l-3.35-3.362M40.586 341.212s-7.323 7.349-14.645 0c-7.323-7.349 0-14.698 0-14.698M88.114 293.923l-6.419-7.083M98.56 285.783l-5.24-7.994M77.98 303.857l-6.748-6.771M72.788 309.03l-3.349-3.363"
        style="fill:none;stroke:#888;stroke-width:1px"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m105.862 255.805 6.564-1.119 4.749 27.872-6.564 1.118-4.749-27.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m112.426 254.686 4.749 27.872"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M112.426 254.686c.599 2.288 1.089 4.595 1.564 6.904.486 2.307.895 4.627 1.302 6.948.385 2.324.768 4.649 1.074 6.987l.457 3.507c.115 1.175.265 2.345.352 3.526-.31-1.143-.556-2.296-.836-3.443l-.731-3.461c-.485-2.307-.894-4.628-1.301-6.948-.383-2.325-.766-4.65-1.072-6.988-.317-2.336-.617-4.675-.809-7.032Z"
        class="gray-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m110.611 283.676-4.749-27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M110.611 283.676c-.599-2.288-1.09-4.595-1.565-6.904-.486-2.307-.894-4.628-1.302-6.948-.385-2.325-.767-4.649-1.074-6.987l-.456-3.507c-.116-1.176-.266-2.345-.352-3.525.31 1.142.555 2.295.836 3.442l.73 3.461c.485 2.307.895 4.627 1.301 6.948.384 2.325.766 4.65 1.072 6.987.317 2.336.618 4.675.81 7.033Z"
        class="gray-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m141.57 249.72 15.089-2.571 4.749 27.871-15.089 2.571-4.749-27.871Z"
        class="white-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m156.659 247.149 4.749 27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m156.659 247.149 4.749 27.871"
        class="gray-stroke"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="m146.319 277.591-4.749-27.871"
        class="no-fill"
        transform="translate(-21.568 -124.439)"
      />
      <path
        d="M146.319 277.591c-.599-2.287-1.091-4.594-1.565-6.903-.486-2.307-.894-4.628-1.303-6.948-.384-2.325-.767-4.65-1.073-6.987l-.456-3.507c-.117-1.176-.266-2.345-.352-3.526.309 1.143.555 2.296.835 3.443l.731 3.46c.485 2.308.894 4.628 1.301 6.949.383 2.324.766 4.65 1.072 6.988.316 2.335.617 4.674.81 7.031Z"
        class="gray-fill"
        transform="translate(-21.568 -124.439)"
      />
      <g v-if="showArrows">
        <path
          d="m67.64 248.297-26.043-26.044-12.464 12.465 26.043 26.044-5.798 5.798 22.449-1.612 1.612-22.449-5.799 5.798ZM199.106 221.244v-36.83h-17.628v36.83h-8.2l17.014 14.735 17.014-14.735h-8.2Z"
          class="orange-arrow"
          transform="translate(-21.568 -124.439)"
        />
        <path
          d="M199.106 221.244v-36.83h-17.628v36.83h-8.2l17.014 14.735 17.014-14.735h-8.2Z"
          style="fill:none;stroke:#fff;stroke-width:1px"
          transform="translate(-21.568 -124.439)"
        />
        <path
          d="m371.252 212.13 26.043-26.043-12.464-12.465-26.044 26.044-5.798-5.799 1.612 22.45 22.449 1.612-5.798-5.799Z"
          class="orange-arrow"
          transform="translate(-21.568 -124.439)"
        />
      </g>
    </g>
  </svg>
</template>
<script setup>
  import { getRaceCode } from "@/utils/GlobalState"

  const race = getRaceCode()
  defineProps({
    showArrows: {
      type: Boolean,
      default: false,
    }
  })
</script>